import { useEffect, useState } from "react"

function useCurrentYear() {
  const [year, setYear] = useState(new Date().getFullYear())

  useEffect(() => {
    const now = new Date()
    setYear(now.getFullYear())
  }, [])

  return year
}

export default useCurrentYear
